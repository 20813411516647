.ifac-theme .div-monthly-invoice .ant-select {
  width: 160px; }

.ifac-theme .div-monthly-invoice td, .ifac-theme .div-monthly-invoice th {
  border-left: 1px solid rgba(0, 0, 0, 0.3); }
  .ifac-theme .div-monthly-invoice td .ant-select-selector, .ifac-theme .div-monthly-invoice th .ant-select-selector {
    min-width: 100px; }

.ifac-theme .div-monthly-invoice th {
  padding: 5px 16px; }

.ifac-theme .div-monthly-invoice td:nth-child(1), .ifac-theme .div-monthly-invoice th:nth-child(1) {
  border-left: none; }
