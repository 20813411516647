.div-monthlyar .ant-select {
  width: 160px; }

.div-monthlyar table .ant-picker {
  min-width: 60px; }

.div-monthlyar table .btn-save {
  min-width: 110px;
  padding-left: 10px;
  text-align: left; }
  .div-monthlyar table .btn-save .anticon svg {
    margin-bottom: -3px;
    height: 20px;
    width: 20px; }
  .div-monthlyar table .btn-save span:not(.anticon) {
    width: 90%;
    text-align: center;
    margin-left: 0px; }
