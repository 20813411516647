.ifac-theme .div-monthly-dd .ant-select {
  width: 160px; }

.ifac-theme .div-monthly-dd td, .ifac-theme .div-monthly-dd th {
  border-left: 1px solid rgba(0, 0, 0, 0.3); }
  .ifac-theme .div-monthly-dd td .ant-select-selector, .ifac-theme .div-monthly-dd th .ant-select-selector {
    min-width: 100px; }

.ifac-theme .div-monthly-dd th {
  padding: 5px 16px; }

.ifac-theme .div-monthly-dd td:nth-child(1), .ifac-theme .div-monthly-dd th:nth-child(1) {
  border-left: none; }
