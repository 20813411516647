.ifac-theme .div-lodgements .ant-table-filter-column-title {
  padding-right: 1em; }

.ifac-theme .div-lodgements .ant-table-filter-trigger {
  width: 1em;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.5); }

.ifac-theme .div-lodgements .ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.9); }

.ifac-theme .div-lodgements .ant-table-filter-trigger-container:hover {
  background-color: unset !important; }

.ifac-theme .div-lodgements .ant-table-filter-trigger-container-open {
  background-color: unset !important; }

.ifac-theme .div-lodgements .ant-table-filter-trigger-container-open > .ant-table-filter-trigger {
  color: rgba(0, 0, 0, 0.9); }

.ifac-theme .div-lodgements .ant-table-filter-column-title {
  padding: 0 !important; }

.ifac-theme .div-lodgements .ant-table-column-sorters {
  padding: 16px 15px 16px 0px; }

.ifac-theme .div-lodgements .ant-table-column-sorter {
  margin-left: 5px;
  margin-right: 10px; }
