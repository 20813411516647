
html, body, .root {
  height: 100%;
}

.div-login {
  float: left;
  width: 50%;
  height: 100%;
  position: relative;
}

.div-text-card {
  color: white;
  font-size : 1.5em;
  padding: 20px;
  background: #08979C;
  box-shadow: -15px 0px 0px #00ABC7, 0px 15px 0px #97C93D
}

.site-layout .site-layout-background {
  background: #fff;
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.ifac-theme p {
  margin-top: unset;
  margin-bottom: unset;
}

.ifac-theme h3 {
  margin-top: unset;
  margin-bottom: unset;
}

.ifac-theme .ant-btn.ant-btn-primary.btn-login {
  background: #08979C;
  box-shadow: -5px 0px 0px #00ABC7, 0px 5px 0px #97C93D
}

.ifac-theme .ant-btn.ant-btn-primary.btn-login:hover {
  background: #005B79;
}

.ifac-theme .ant-btn.ant-btn-primary.btn-login:active {
  background: #005B79;
  box-shadow: none;
}

.ifac-theme .ant-layout-sider{
  background-color: #005B79;
}

.ifac-theme .ant-menu.ant-menu-dark, .ifac-theme .ant-menu.ant-menu-sub {
  background-color: #005B79 !important;
}

.ifac-theme .ant-menu-dark .ant-menu-inline.ant-menu-sub{
  background-color: #005B79;
}

.ifac-theme .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #00ABC7;
}



.ifac-theme .ant-layout-sider-trigger{
  background-color: #00ABC7;
}

.ifac-theme .ant-layout-sider {
  flex: 0 0 270px !important;
  max-width: 270px !important;
  min-width: 270px !important;
  width: 270px !important;
}

.ifac-theme .ant-layout-sider.ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
}

.ifac-theme .ant-layout-sider .ant-layout-sider-trigger{
    width: 270px !important;
}

.ifac-theme .ant-layout-sider.ant-layout-sider-collapsed .ant-layout-sider-trigger{
    width: 80px !important;
}

.ifac-theme .error-text {
    color: rgb(230, 0, 0);
    text-align: center;
}

.ifac-theme .site-layout-background:not(.ant-layout-header) {
      max-height: 100%;
      height: 100%;
      overflow: "auto";
      padding-top: 10px !important;
      padding-bottom: 0px !important;
}

.ifac-theme .pdf-container iframe {
    height: 100%;
}
.ifac-theme .ant-breadcrumb {
  margin: 0px !important;
}

.ifac-theme .ant-layout-content {
  height: 100%;
  margin: 0px !important;
}

.ifac-theme .ant-layout {
  max-height: 100%;
  height: 100%;
}

.ifac-theme .p-cancelled {
  color: red;
  text-decoration: line-through;
}

.ifac-theme .p-red {
  color: red;
}

.ifac-theme .ant-btn.btn-save {
  border: 0px;
  color: white;
  background: rgb(0,183,79);
}

.ifac-theme .ant-btn.btn-save[disabled] {
  background: rgba(165, 165, 165, 0.897);
}

.ifac-theme .ant-btn.btn-save:hover {
  color: white;
  background: rgb(0, 150, 65);
}

.ifac-theme .ant-btn.btn-save:hover[disabled] {
  color: white;
  background: rgba(165, 165, 165, 0.897);
}

.ifac-theme .ant-btn.btn-cancel {
  padding:4px 10px;
  border: 0px;
  color: white;
  background: rgba(241, 41, 41, 0.9);
}

.ifac-theme .ant-btn.btn-cancel[disabled] {
  background: rgba(165, 165, 165, 0.897);
}

.ifac-theme .ant-btn.btn-cancel:hover {
  background: rgb(201, 23, 23);
}

.ifac-theme .ant-btn.btn-cancel:hover[disabled] {
  background: rgba(165, 165, 165, 0.897);
  color: white;
}

.ifac-theme .div-payroll .ant-input {
  width:80px;
  text-align: center;
}

.ifac-theme .div-payroll .ant-table-cell {
  text-align: center;
  width: 5%;
}

.ifac-theme .ant-tabs {
  width:100%;
  text-align: center;
}

.ifac-theme .ant-layout-header {
  border-bottom: 3px solid rgb(157,150,141);
  height: fit-content;
}

.ifac-theme .ant-layout-header h2 {
  font-weight: bold;
  font-style: italic;
  color: rgb(0,43,73);
  margin-bottom: 0px;
}

.ifac-theme .ant-modal-confirm-body-wrapper::after {
  display: unset;
}

.ifac-theme .ant-modal-content {
  width: 500px;
}

.ifac-theme .div-lodgements .ant-input {
  width: 100px;
}

.ifac-theme .div-lodgements .ant-picker {
  width: 120px;
}

.ifac-theme .btn-save-icon {
  background-color: transparent;
  border: none;
  color: rgb(0,183,79);
}

.ifac-theme .btn-save-icon[disabled] {
  background-color: transparent;
  color: rgba(165, 165, 165, 0.897);
}

.ifac-theme .btn-save-icon:hover {
  background-color: transparent;
  color: rgb(0, 150, 65);
}

.ifac-theme .btn-save-icon:hover[disabled] {
  background-color: transparent;
  color: rgba(165, 165, 165, 0.897);
}

.ifac-theme .btn-save-icon svg {
  font-size: 30px;
}

.ifac-theme .btn-delete-icon {
  background-color: transparent;
  border: none;
  color: rgba(241, 41, 41, 0.9);
}

.ifac-theme .btn-delete-icon[disabled] {
  background-color: transparent;
  color: rgba(165, 165, 165, 0.897);
}

.ifac-theme .btn-delete-icon:hover {
  background-color: transparent;
  color: rgb(201, 23, 23);
}

.ifac-theme .btn-delete-icon:hover[disabled] {
  background-color: transparent;
  color: rgba(165, 165, 165, 0.897);
}

.ifac-theme .btn-delete-icon svg {
  font-size: 30px;
}

.ifac-theme .ant-table-summary {
  font-weight: bold;
}

.ifac-theme .ant-table-summary tr td {
  text-align: right;
}

.ifac-theme .text-align-center {
  text-align: center !important;
}

.ifac-theme .text-align-left {
  text-align: left !important;
}
