.ifac-theme .text-right {
  text-align: right; }

.ifac-theme .text-center {
  text-align: center; }

.ifac-theme .text-bold {
  font-weight: bold; }

.ifac-theme .has-popover {
  cursor: pointer;
  transition: 0.3s; }

.ifac-theme .has-popover:hover {
  color: #0000EE; }

.ifac-theme .div-non-ant-table table {
  width: 100%;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 14px; }
  .ifac-theme .div-non-ant-table table .ant-select, .ifac-theme .div-non-ant-table table .ant-input {
    width: 100%;
    max-width: 150px;
    text-align: inherit; }
  .ifac-theme .div-non-ant-table table .ant-input {
    max-width: 100px; }

.ifac-theme .div-non-ant-table th {
  background-color: rgba(0, 0, 0, 0.03);
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  width: 10%;
  height: 45px;
  font-weight: 500;
  border-left: 1px solid rgba(0, 0, 0, 0.3); }

.ifac-theme .div-non-ant-table td {
  background-color: white;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  width: 10%;
  height: 45px;
  padding: 5px 16px;
  border-left: 1px solid rgba(0, 0, 0, 0.3); }

.ifac-theme .div-non-ant-table tr:nth-of-type(even) td {
  background-color: rgba(0, 0, 0, 0.03); }

.ifac-theme .div-non-ant-table td:nth-child(1), .ifac-theme .div-non-ant-table th:nth-child(1) {
  border-left: none; }

.ifac-theme .div-ant-table table {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  border-top: 1px solid rgba(0, 0, 0, 0.3); }

.ifac-theme .div-ant-table td, .ifac-theme .div-ant-table th {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 10%;
  height: 45px;
  border-color: rgba(0, 0, 0, 0.3);
  border-left: 1px solid rgba(0, 0, 0, 0.3); }
  .ifac-theme .div-ant-table td .ant-select-selector, .ifac-theme .div-ant-table th .ant-select-selector {
    min-width: 100px; }

.ifac-theme .div-ant-table th {
  text-align: center !important; }

.ifac-theme .div-ant-table tr:nth-of-type(even) td {
  background-color: rgba(0, 0, 0, 0.03); }

.ifac-theme .div-ant-table td:nth-child(1), .ifac-theme .div-ant-table th:nth-child(1) {
  border-left: none; }

.ifac-theme .border-top td {
  border-top: 1px solid rgba(0, 0, 0, 0.3); }

.ifac-theme .border-bottom td {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3); }

.ifac-theme .center-cells td {
  text-align: center; }

.ifac-theme .ant-modal-content {
  width: fit-content; }

.ifac-theme .ant-modal-footer {
  display: none; }

.ifac-theme .ant-modal-close-x {
  display: none; }

.ifac-theme .ant-popover-inner-content {
  border: 1px solid black; }

.ifac-theme .ant-popover-arrow {
  border-right-color: black !important;
  border-bottom-color: black !important; }

.ifac-theme .div-layout .ant-image {
  display: flex;
  justify-content: center; }

.ifac-theme .ant-table-selection-column {
  display: none; }

.ifac-theme .div-ant-table.div-selectable-rows tr:nth-of-type(even):not(.ant-table-row-selected) td {
  background-color: rgba(0, 0, 0, 0.03); }

.ifac-theme .div-ant-table.div-selectable-rows tr:nth-of-type(even):not(.ant-table-row-selected):hover td {
  background-color: rgba(0, 171, 199, 0.1); }

.ifac-theme .div-ant-table.div-selectable-rows .ant-table-row-selected td {
  background-color: rgba(0, 171, 199, 0.3);
  border-color: rgba(0, 0, 0, 0.3); }

.ifac-theme .div-ant-table.div-selectable-rows .ant-table-row-selected:hover td {
  background-color: rgba(0, 171, 199, 0.3); }

.ifac-theme .div-ant-table.div-selectable-rows tr:hover td {
  background-color: rgba(0, 171, 199, 0.1); }
